.order {
  margin: 1px;
  padding: 1px;
  background-color: #230052;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  text-align: center;
  color: white;
}

.currentOrder {
  margin: 1px;
  padding: 1px;
  background-color: #005247;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  text-align: center;
  font-size: 1.4rem;
  color: #f7e702;
}

.order h2 {
  font-size: 2rem;
  color: #f7e702;
}

.order h3 {
  color: #eccf77;
  margin: 0;
  font-size: 1rem;
}

