section {
  margin: 1rem auto;
  width: 90%;
  max-width: 40rem;
  text-align: center;
  background-color: rgb(197, 98, 98);
  padding: 0.5rem;
  border-radius: 12px;
}

button {
  font: inherit;
  cursor: pointer;
  background: #230052;
  border: 1px solid #230052;
  color: white;
  padding: 0.75rem 0.5rem;
  border-radius: 20px;
}

button:focus {
  outline: none;
}

button:hover,
button:active {
  background-color: #460897;
  border-color: #460897;
}

.coltitle {
  color: rgb(197, 98, 98);
  font-size: 4vh;
}
